import facebook_logo from '../assets/images/company/facebook-logo.png';
import google_logo from '../assets/images/company/google-logo.png';
import android from '../assets/images/company/android.png';
import lenovo_logo from '../assets/images/company/lenovo-logo.png';
import spotify from '../assets/images/company/spotify.png';
import linkedin from '../assets/images/company/linkedin.png';
import React from 'react'
import { Link } from "react-router-dom";

export default function Jobs() {
    const Jobs = [
        {
            image: facebook_logo,
            title: 'Facebook',
            day: '2 days ago',
            jobtype: 'Full Time',
            heading: 'Web Designer / Developer',
            Location: 'Australia',
            vacancy: '21 applied of 40 vacancy'
        },
        {
            image: google_logo,
            title: 'Google',
            day: '2 days ago',
            jobtype: 'Part Time',
            heading: 'Marketing Director',
            Location: 'USA',
            vacancy: '21 applied of 40 vacancy'
        },
        {
            image: android,
            title: 'Android',
            day: '2 days ago',
            jobtype: 'Remote',
            heading: 'Application Developer',
            Location: ' China',
            vacancy: '21 applied of 40 vacancy'
        },
        {
            image: lenovo_logo,
            title: 'Lenovo',
            day: '2 days ago',
            jobtype: 'WFH',
            heading: 'Senior Product Designer',
            Location: 'Dubai',
            vacancy: '21 applied of 40 vacancy'
        },
        {
            image: spotify,
            title: ' Spotify',
            day: '2 days ago',
            jobtype: 'Full Time',
            heading: 'Web Designer / Developer',
            Location: 'Australia',
            vacancy: '21 applied of 40 vacancy'
        },
        {
            image: linkedin,
            title: ' Linkedin',
            day: '2 days ago',
            jobtype: 'Remote Time',
            heading: 'Php Developer',
            Location: 'Pakistan',
            vacancy: '21 applied of 40 vacancy'
        },
    ]
    return (

        <section className="relative bg-slate-50 dark:bg-slate-800 md:py-24 py-16">
            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold"> Our Porduct & Services</h3>

                    <p className="text-slate-400 max-w-xl mx-auto">Whether it's working with rice farmers in Asia, sourcing cotton in Australia, or producing value-added products in Africa, we help our customers around the world meet the increasing demand for food, feed and fibre..</p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">

                    {Jobs.map((item, index) => (
                        <div className="group shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900" key={index}>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                                        <img src={item.image} className="h-8 w-8" alt="" />
                                    </div>

                                    <div className="ms-3">
                                        <Link to="/employer-detail" className="block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500">{item.title}</Link>
                                        <span className="block text-sm text-slate-400">{item.day}</span>
                                    </div>
                                </div>

                                <span className="bg-emerald-600/10 group-hover:bg-emerald-600 inline-block text-emerald-600 group-hover:text-white text-xs px-2.5 py-0.5 font-semibold rounded-full transition-all duration-500">{item.jobtype}</span>
                            </div>

                            <div className="mt-6">
                                <Link to="/job-detail-two" className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500">{item.heading}</Link>
                                <h6 className="text-base font-medium"><i className="uil uil-map-marker"></i> {item.Location}</h6>
                            </div>

                            <div className="mt-6">
                                <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                                    <div className="bg-emerald-600 h-[6px] rounded-full" style={{ width: '55%' }}></div>
                                </div>
                                <div className="mt-2">
                                    <span className="text-slate-400 text-sm"><span className="text-slate-900 dark:text-white font-semibold inline-block">21 applied</span> of 40 vacancy</span>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>

                <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                    <div className="md:col-span-12 text-center">
                        <Link to="/job-grid-two" className="btn btn-link text-slate-400 hover:text-emerald-600 after:bg-emerald-600 duration-500 ease-in-out">See More Jobs <i className="uil uil-arrow-right align-middle"></i></Link>
                    </div>
                </div>
            </div>
        </section>

    )
}
