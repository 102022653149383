import React from 'react'
import { Link } from "react-router-dom";
import lenovo_logo from '../../assets/images/company/lenovo-logo.png';
import JobDetailComp from '../../components/job-detail-comp';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import { Book, Briefcase, Clock, DollarSign, MapPin, Monitor, UserCheck } from 'react-feather';

export default function JobDetailThree() {
    return (
        <>

            <Navbar navClass='justify-end' />
            <section className="bg-slate-50 dark:bg-slate-800 md:py-24 py-16">
                <div className="container mt-10">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-4 md:col-span-6">
                            <div className="p-6 shadow dark:shadow-gray-700 rounded-md bg-white dark:bg-slate-900 sticky top-20">
                                <img src={lenovo_logo} className="rounded-full h-28 w-28 p-4 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700" alt="" />

                                <div className="md:ms-4 mt-4">
                                    <h5 className="text-xl font-semibold">Back-End Developer</h5>
                                    <div className="mt-1">
                                        <span className="text-slate-400 font-medium me-2 inline-block"><i className="uil uil-building text-[18px] text-emerald-600 me-1"></i> Lenovo pvt. ltd.</span>
                                        <span className="text-slate-400 font-medium me-2 inline-block"><i className="uil uil-map-marker text-[18px] text-emerald-600 me-1"></i> Beijing, China</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-8 md:col-span-6">
                            <h5 className="text-lg font-semibold">Job Information:</h5>
                            <ul className="list-none mt-5">
                                <li className="inline-flex items-center py-2 px-4 bg-white dark:bg-slate-900 me-2 my-1 shadow dark:shadow-gray-700 rounded-md">
                                    <UserCheck className="h-5 w-5"></UserCheck>

                                    <div className="ms-4">
                                        <p className="font-medium">Employee Type:</p>
                                        <span className="text-emerald-600 font-medium text-sm">Full Time</span>
                                    </div>
                                </li>

                                <li className="inline-flex items-center py-2 px-4 bg-white dark:bg-slate-900 me-2 my-1 shadow dark:shadow-gray-700 rounded-md">
                                    <MapPin className="h-5 w-5"></MapPin>

                                    <div className="ms-4">
                                        <p className="font-medium">Location:</p>
                                        <span className="text-emerald-600 font-medium text-sm">Beijing, China</span>
                                    </div>
                                </li>

                                <li className="inline-flex items-center py-2 px-4 bg-white dark:bg-slate-900 me-2 my-1 shadow dark:shadow-gray-700 rounded-md">
                                    <Monitor className="h-5 w-5"></Monitor>

                                    <div className="ms-4">
                                        <p className="font-medium">Job Type:</p>
                                        <span className="text-emerald-600 font-medium text-sm">Back-end Developer</span>
                                    </div>
                                </li>

                                <li className="inline-flex items-center py-2 px-4 bg-white dark:bg-slate-900 me-2 my-1 shadow dark:shadow-gray-700 rounded-md">
                                    <Briefcase className="h-5 w-5"></Briefcase>

                                    <div className="ms-4">
                                        <p className="font-medium">Experience:</p>
                                        <span className="text-emerald-600 font-medium text-sm">2+ years</span>
                                    </div>
                                </li>

                                <li className="inline-flex items-center py-2 px-4 bg-white dark:bg-slate-900 me-2 my-1 shadow dark:shadow-gray-700 rounded-md">
                                    <Book className="h-5 w-5"></Book>

                                    <div className="ms-4">
                                        <p className="font-medium">Qualifications:</p>
                                        <span className="text-emerald-600 font-medium text-sm">MCA</span>
                                    </div>
                                </li>

                                <li className="inline-flex items-center py-2 px-4 bg-white dark:bg-slate-900 me-2 my-1 shadow dark:shadow-gray-700 rounded-md">
                                    <DollarSign className="h-5 w-5"></DollarSign>

                                    <div className="ms-4">
                                        <p className="font-medium">Salary:</p>
                                        <span className="text-emerald-600 font-medium text-sm">$4000 - $4500</span>
                                    </div>
                                </li>

                                <li className="inline-flex items-center py-2 px-4 bg-white dark:bg-slate-900 me-2 my-1 shadow dark:shadow-gray-700 rounded-md">
                                    <Clock className="h-5 w-5"></Clock>

                                    <div className="ms-4">
                                        <p className="font-medium">Date posted:</p>
                                        <span className="text-emerald-600 font-medium text-sm">28th Feb, 2023</span>
                                    </div>
                                </li>
                            </ul>

                            <h5 className="text-lg font-semibold mt-6">Job Description:</h5>

                            <p className="text-slate-400 mt-4">One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others - which creates a distinct visual impression. Moreover, in Latin only words at the beginning of sentences are capitalized.</p>
                            <p className="text-slate-400 mt-4">This means that Lorem Ipsum cannot accurately represent, for example, German, in which all nouns are capitalized. Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. If the fill text is intended to illustrate the characteristics of different typefaces.</p>
                            <p className="text-slate-400 mt-4">It sometimes makes sense to select texts containing the various letters and symbols specific to the output language.</p>

                            <h5 className="text-lg font-semibold mt-6">Responsibilities and Duties: </h5>
                            <p className="text-slate-400 mt-4">It sometimes makes sense to select texts containing the various letters and symbols specific to the output language.</p>
                            <ul className="list-none">
                                <li className="text-slate-400 mt-2"><i className="uil uil-arrow-right text-emerald-600 me-1"></i>Participate in requirements analysis</li>
                                <li className="text-slate-400 mt-2"><i className="uil uil-arrow-right text-emerald-600 me-1"></i>Write clean, scalable code using C# and .NET frameworks</li>
                                <li className="text-slate-400 mt-2"><i className="uil uil-arrow-right text-emerald-600 me-1"></i>Test and deploy applications and systems</li>
                                <li className="text-slate-400 mt-2"><i className="uil uil-arrow-right text-emerald-600 me-1"></i>Revise, update, refactor and debug code</li>
                                <li className="text-slate-400 mt-2"><i className="uil uil-arrow-right text-emerald-600 me-1"></i>Improve existing software</li>
                                <li className="text-slate-400 mt-2"><i className="uil uil-arrow-right text-emerald-600 me-1"></i>Develop documentation throughout the software development life cycle (SDLC)</li>
                                <li className="text-slate-400 mt-2"><i className="uil uil-arrow-right text-emerald-600 me-1"></i>Serve as an expert on applications and provide technical support</li>
                            </ul>

                            <h5 className="text-lg font-semibold mt-6">Required Experience, Skills and Qualifications: </h5>
                            <p className="text-slate-400 mt-4">It sometimes makes sense to select texts containing the various letters and symbols specific to the output language.</p>
                            <ul className="list-none">
                                <li className="text-slate-400 mt-2"><i className="uil uil-arrow-right text-emerald-600 me-1"></i>Proven experience as a .NET Developer or Application Developer</li>
                                <li className="text-slate-400 mt-2"><i className="uil uil-arrow-right text-emerald-600 me-1"></i>good understanding of SQL and Relational Databases, specifically Microsoft SQL Server.</li>
                                <li className="text-slate-400 mt-2"><i className="uil uil-arrow-right text-emerald-600 me-1"></i>Experience designing, developing and creating RESTful web services and APIs</li>
                                <li className="text-slate-400 mt-2"><i className="uil uil-arrow-right text-emerald-600 me-1"></i>Basic know how of Agile process and practices</li>
                                <li className="text-slate-400 mt-2"><i className="uil uil-arrow-right text-emerald-600 me-1"></i>Good understanding of object-oriented programming.</li>
                                <li className="text-slate-400 mt-2"><i className="uil uil-arrow-right text-emerald-600 me-1"></i>Good understanding of concurrent programming.</li>
                                <li className="text-slate-400 mt-2"><i className="uil uil-arrow-right text-emerald-600 me-1"></i>Sound knowledge of application architecture and design.</li>
                                <li className="text-slate-400 mt-2"><i className="uil uil-arrow-right text-emerald-600 me-1"></i>Excellent problem solving and analytical skills</li>
                            </ul>

                            <div className="mt-5">
                                <Link to="/job-apply" className="btn rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white md:ms-2 w-full md:w-auto">Apply Now</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <JobDetailComp />

                <div className="container-fluid md:mt-24 mt-16">
                    <div className="container">
                        <div className="grid grid-cols-1">
                            <div className="relative overflow-hidden lg:px-8 px-6 py-10 rounded-xl shadow-lg dark:shadow-gray-700">
                                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                                    <div className="lg:col-span-8 md:col-span-7">
                                        <div className="ltr:md:text-left rtl:md:text-right text-center relative z-1">
                                            <h3 className="text-2xl font-semibold text-black dark:text-white mb-4">Explore a job now!</h3>
                                            <p className="text-slate-400 max-w-xl">Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 30000+ companies worldwide.</p>
                                        </div>
                                    </div>

                                    <div className="lg:col-span-4 md:col-span-5">
                                        <div className="ltr:text-right rtl:text-left relative z-1">
                                            <Link to="/employer-detail" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md">Apply Now</Link>
                                            <Link to="/aboutus" className="btn bg-emerald-600/5 hover:bg-emerald-600 border-emerald-600/10 hover:border-emerald-600 text-emerald-600 hover:text-white rounded-md ms-2">Learn More</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="absolute -top-5 -start-5">
                                    <div className="uil uil-envelope lg:text-[150px] text-7xl text-black/5 dark:text-white/5 ltr:-rotate-45 rtl:rotate-45"></div>
                                </div>

                                <div className="absolute -bottom-5 -end-5">
                                    <div className="uil uil-pen lg:text-[150px] text-7xl text-black/5 dark:text-white/5 rtl:-rotate-90"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />


        </>
    )
}
