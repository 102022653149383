//  index-five     //
import React from 'react'
import { Link } from 'react-router-dom';
import bean from '../assets/images/hero/beans.png';
import rice from '../assets/images/hero/rice11.png';
import maize from '../assets/images/hero/maize3.png';
import poultry from '../assets/images/hero/poulltry-1.png';
import Sculpture1 from '../assets/images/hero/Sculpture1.jpg';
import Sculpture2 from '../assets/images/hero/Sculpture2.jpg';
import Sculpture3 from '../assets/images/hero/Sculpture3.jpg';



export default function PopularCategories() {
    const Categories = [
        {
            image: poultry,
            title: 'Poultry',
        },
        {
            image: bean,
            title: 'Beans',
        },
        {
            image: maize,
            title: 'Maize',
            style: 'size: 87px; height: 87px; width: 89px;'
        },
        {
            image: rice,
            title: 'Rice',
        }
    ];

    return (
        <div className="container md:mt-24 mt-16">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">
                    Dedune Tropical focus areas
                </h3>
                <p className="text-slate-300 max-w-lg mx-auto" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
                    Dedune Tropical is focused on the preservation of African History and Culture. We achieve this by participating in festivals and promotion events. We also reach out to educational institutions to partner them in educating the kids about the beauty of African history and the need to celebrate and preserve it. This helps in promoting peaceful coexistence in our evolving multicultural society, build their pride and confidence and develops the love for their culture within them.
                </p>
            </div>
            <div className="container mx-auto px-4" style={{marginBottom: '5rem'}}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px] justify-center items-center">
                    <img src={Sculpture1} alt="event1" className="w-full  object-cover" style={{height: '93%', width: "100%"}}/>
                    <img src={Sculpture2} alt="event2" className="w-full  object-cover " />
                    <img src={Sculpture3} alt="event3" className="w-full  object-cover" style={{height: '93%', width: "100%"}}/>
                </div>

            </div>
            <div>
                <p className="text-slate-300 max-w-lg mx-auto" style={{ marginBottom: '1rem', textAlign: 'justify', textJustify: 'inter-word' }}>
                    The company also focuses on the development and marketing of agricultural produce. Our supply chain
                    management solutions focus on streamlining the movement of agricultural products from the farm to market.
                </p>
                <p className="text-slate-300 max-w-lg mx-auto" style={{ textAlign: 'justify', textJustify: 'inter-word', marginBottom: '5rem' }}>
                    We educate and encourage farmers on the need to adopt mecahnized and modernized agricultural practices to reduce the cost of production,
                    post harvest losses and increase productivity to ensure food security and sustainable livelihoods.
                </p>
            </div>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                {Categories.map((item, index) => (
                    <div className="group px-3 py-10 rounded-full shadow dark:shadow-gray-700 hover:shadow-emerald-700/10 dark:hover:shadow-emerald-600/10 text-center bg-white dark:bg-slate-900 hover:bg-emerald-600/5 dark:hover:bg-emerald-600/5 transition duration-500" key={index} >
                        <div className="w-20 h-20 bg-emerald-600/5 group-hover:bg-emerald-600 text-emerald-600 group-hover:text-white rounded-full text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-700 transition duration-500 mx-auto">
                            <img src={item.image} className="h-15 w-15" alt="" />
                        </div>

                        <div className="content mt-6">
                            <Link to="#" className="title text-lg font-semibold hover:text-emerald-600">{item.title}</Link>
                            <p className="text-slate-400 mt-3">{item.total}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
