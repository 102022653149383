import React, { useState } from 'react'
import ab06 from '../../assets/images/about/ab06.avif';
import ab07 from '../../assets/images/about/ab07.jpg';
import Navbar from '../../components/Navbar'
import Works from '../../components/How-Work'
import PopularJobsSeven from '../../components/Popular-Jobs-Seven'
import QuesAnswer from '../../components/Question-Answer'
import Footer from '../../components/Footer'
import { Link } from "react-router-dom";
import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import PopularCategories from '../../components/Popular-Categories';


export default function IndexSeven() {
    const [isOpen, setOpen] = useState(false)

    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative flex md:h-screen h-auto items-center bg-[url('../../assets/images/hero/bg7.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute top-0 start-0 w-full h-full z-0 pointer-events-none overflow-hidden">
                    {/* <iframe title="iframe-1" src="https://player.vimeo.com/video/354421650?background=1&autoplay=1&loop=1&byline=0&title=0" className="absolute top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2  -translate-y-1/2 w-screen h-[56.25vw] min-h-screen min-w-[177.77vw]"></iframe>
                    <iframe title="iframe-2" src="https://www.youtube.com/embed/vcYHrXXSzR8?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1" className="absolute top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2  -translate-y-1/2 w-screen h-[56.25vw] min-h-screen min-w-[177.77vw]"></iframe> */}
                </div>
                <div className="absolute inset-0 bg-emerald-900/80"></div>
                <div className="container z-1">
                    <div className="grid grid-cols-1 mt-10 relative">
                        <h3 className="text-left lg:leading-normal leading-normal text-9xl lg:text-5xl mb-5 font-bold text-white">
                          Promoting African Artifacts <br/>and Sculptures, And a leading <br/> Agribusiness company.
                        </h3>
                        <p className="text-left lg:leading-normal leading-normal text-base mb-8 text-white" style={{width: '60%'}}>
                            Dedune Tropical is a private sector-led agribusiness company that is committed to the transformation of the agricultural
                             sector in Ghana to promote sustainable agriculture and agribusiness and improve the livelihoods of smallholder farmers.
                        </p>
                        {/* <button className="btn hover:bg-emerald-700 text-white rounded-md" style={{ width: '10rem' }}>Learn More</button> */}
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <Works />
                {/* <BrowseByCategories /> */}
                <PopularCategories />
            </section>
            {/* <section className="py-24 w-full table relative bg-[url('../../assets/images/hero/bg3.jpg')] bg-center bg-no-repeat bg-cover jarallax" data-jarallax data-speed="0.5">
                <div className="absolute inset-0 bg-slate-900/40"></div>
                <div className="container relative">
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-[30px]">
                        <div>
                            <h5 className="text-xl font-semibold text-white">Register for beneficiary</h5>

                            <ul className="list-none text-white mt-4">
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-white text-xl me-2"></i> Many desktop publishing packages</li>
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-white text-xl me-2"></i> Contrary to popular belief</li>
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-white text-xl me-2"></i> It is a long established fact that a reader</li>
                            </ul> */}

                            {/* <div className="mt-4">
                                <Link to="/job-post" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full">Learn More</Link>
                            </div> */}
                        {/* </div>
                    </div>
                </div>

            </section> */}
            <section className="relative md:py-24 py-16">
                <PopularJobsSeven />
                <div className="container md:mt-24 md:pb-24 pb-16 mt-16">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="relative">
                                <div className="relative">
                                    <img src={ab06}
                                        className="lg:w-[400px] w-[280px] rounded-md shadow dark:shadow-gray-700" alt="" />
                                    <div className="absolute top-0 translate-y-2/4 end-0 text-center">
                                        {/* <Link to="#" onClick={() => setOpen(true)} data-type="youtube" data-id="yba7hPeTSjk"
                                            className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-700 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-emerald-600 dark:text-white">
                                            <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                        </Link> */}
                                    </div>
                                </div>
                                <div className="absolute md:-end-5 end-0 -bottom-16">
                                    <img src={ab07}
                                        className="lg:w-[280px] w-[200px] border-8 border-white dark:border-slate-900 rounded-md shadow dark:shadow-gray-700"
                                        alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-7 md:col-span-6 mt-14 md:mt-0">
                            <div className="lg:ms-5">
                                < h3 className="mb-6 md:text-[26px] text-2xl md:leading-normal leading-normal font-semibold">
                                    Partnership programs
                                </h3>
                                <p className="text-slate-300 max-w-xl">
                                    Dedune Tropical Limited has partnered with several organizations to implement various programs aimed at improving the agricultural sector in Ghana. Some of the programs include:
                                </p>

                                <ul className="list-none text-slate-400 mt-4">
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-emerald-600 text-xl me-2">
                                    </i>Education and Training the local farmers in mecahnized farming and modern farming techniques</li>
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-emerald-600 text-xl me-2">
                                    </i>Disability Inclusive Agriculture for Prosperity</li>
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-emerald-600 text-xl me-2">
                                    </i> Educating women in the need to get involved in agricultural and agribusiness</li>
                                </ul>

                                <div className="mt-6">
                                    <Link to="/contact"
                                        className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white mt-2 rounded-md"><i
                                            className="uil uil-envelope"></i> Contact us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <JobCounter /> */}
                <QuesAnswer />

                <div className="container-fluid md:mt-24 mt-16">
                    <div className="container">
                        <div className="grid grid-cols-1">
                            <div className="relative overflow-hidden lg:px-8 px-6 py-10 rounded-xl shadow-lg dark:shadow-gray-700">
                                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                                    <div className="lg:col-span-8 md:col-span-7">
                                        <div className="ltr:md:text-left rtl:md:text-right text-center relative z-1">
                                            <h3 className="text-2xl font-semibold text-black dark:text-white mb-4">Talk to us
                                            </h3>
                                            <p className="text-slate-400 max-w-xl">We are always available to answer your questions and provide you with the best solutions to your agricultural needs. Feel free to contact us.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="lg:col-span-4 md:col-span-5">
                                        <div className="ltr:text-right rtl:text-left relative z-1">
                                            <Link to="/employer-detail"
                                                className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md">
                                                Contact US</Link>
                                            <Link to="/aboutus"
                                                className="btn bg-emerald-600/5 hover:bg-emerald-600 border-emerald-600/10 hover:border-emerald-600 text-emerald-600 hover:text-white rounded-md ms-2">Learn
                                                More</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="absolute -top-5 -start-5">
                                    <div
                                        className="uil uil-envelope lg:text-[150px] text-7xl text-black/5 dark:text-white/5 ltr:-rotate-45 rtl:rotate-45">
                                    </div>
                                </div>

                                <div className="absolute -bottom-5 -end-5">
                                    <div className="uil uil-pen lg:text-[150px] text-7xl text-black/5 dark:text-white/5 rtl:-rotate-90">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="yba7hPeTSjk" onClose={() => setOpen(false)} />
            </section>
            <Footer />
        </>
    )
}
