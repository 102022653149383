import facebook_logo from '../assets/images/company/facebook-logo.png';
import google_logo from '../assets/images/company/google-logo.png';
import android from '../assets/images/company/android.png';
import lenovo_logo from '../assets/images/company/lenovo-logo.png';
import spotify from '../assets/images/company/spotify.png';
import linkedin from '../assets/images/company/linkedin.png';
import circle_logo from '../assets/images/company/circle-logo.png';
import skype from '../assets/images/company/skype.png';
import snapchat from '../assets/images/company/snapchat.png';
import shree_logo from '../assets/images/company/shree-logo.png';
import telegram from '../assets/images/company/telegram.png';
import whatsapp from '../assets/images/company/whatsapp.png';
import React from 'react'
import { Link } from "react-router-dom";
import { Bookmark } from 'react-feather';

export default function JobListComp() {
    const JobListComp = [
        {
            image: facebook_logo,
            title: 'Web Designer',
            jobtype: 'Full Time',
            date: '20th Feb 2023',
            country: 'Australia',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: google_logo,
            title: 'Marketing Director',
            jobtype: 'Part Time',
            date: '20th Feb 2023',
            country: 'USA',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: android,
            title: 'App Developer',
            jobtype: 'Remote',
            date: '20th Feb 2023',
            country: 'China',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: lenovo_logo,
            title: 'Product Designer',
            jobtype: 'WFH',
            date: '20th Feb 2023',
            country: 'Dubai',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: spotify,
            title: ' C++ Developer',
            jobtype: 'Full Time',
            date: '20th Feb 2023',
            country: 'India',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: linkedin,
            title: 'Php Developer',
            jobtype: 'Remote',
            date: '20th Feb 2023',
            country: 'Pakistan',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: circle_logo,
            title: 'Web Designer',
            jobtype: 'Full Time',
            date: '20th Feb 2023',
            country: 'Australia',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: skype,
            title: 'Marketing Director',
            jobtype: 'Part Time',
            date: '20th Feb 2023',
            country: 'USA',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: snapchat,
            title: 'App Developer',
            jobtype: 'Remote',
            date: '20th Feb 2023',
            country: 'China',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: shree_logo,
            title: 'Product Designer',
            jobtype: 'WFH',
            date: '20th Feb 2023',
            country: 'Dubai',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: telegram,
            title: 'C++ Developer',
            jobtype: 'Full Time',
            date: '20th Feb 2023',
            country: 'India',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: whatsapp,
            title: 'Php Developer',
            jobtype: 'Remote',
            date: '20th Feb 2023',
            country: 'Pakistan',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        }
    ]





    return (
        <>

            <div className="container mt-10">
                <div className="grid grid-cols-1 gap-[30px]">


                    {JobListComp.map((item, index) => (
                        <div className="group relative overflow-hidden md:flex justify-between items-center rounded shadow hover:shadow-md dark:shadow-gray-700 transition-all duration-500 p-5" key={index}>
                            <div className="flex items-center">
                                <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                                    <img src={item.image} className="h-8 w-8" alt="" />
                                </div>
                                <Link to="/job-detail-two" className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500 ms-3 min-w-[180px]">{item.title}</Link>
                            </div>

                            <div className="md:block flex justify-between md:mt-0 mt-4">
                                <span className="block"><span className="bg-emerald-600/10 inline-block text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full">"{item.jobtype}</span></span>
                                <span className="block text-slate-400 text-sm md:mt-1 mt-0"><i className="uil uil-clock"></i>{item.date}</span>
                            </div>

                            <div className="md:block flex justify-between md:mt-0 mt-2">
                                <span className="text-slate-400"><i className="uil uil-map-marker"></i>{item.country}</span>
                                <span className="block font-semibold md:mt-1 mt-0">{item.price}</span>
                            </div>

                            <div className="md:mt-0 mt-4">
                                <Link to="#" className="btn btn-icon rounded-full bg-emerald-600/5 hover:bg-emerald-600 border-emerald-600/10 hover:border-emerald-600 text-emerald-600 hover:text-white md:relative absolute top-0 end-0 md:m-0 m-3"><Bookmark className="h-4 w-4"></Bookmark></Link>
                                <Link to="/job-apply" className="btn rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white md:ms-2 w-full md:w-auto">{item.apply}</Link>
                            </div>

                            <span className="w-24 bg-yellow-400 text-white text-center absolute ltr:-rotate-45 rtl:rotate-45 -start-[30px] top-1"><i className="uil uil-star"></i></span>
                        </div>
                    ))}



                </div>
            </div>
        </>
    )
}
