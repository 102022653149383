import facebook_logo from '../assets/images/company/facebook-logo.png';
import google_logo from '../assets/images/company/google-logo.png';
import linkedin from '../assets/images/company/linkedin.png';
import skype from '../assets/images/company/skype.png';
import spotify from '../assets/images/company/spotify.png';
import telegram from '../assets/images/company/telegram.png';
import circle_logo from '../assets/images/company/circle-logo.png';
import snapchat from '../assets/images/company/snapchat.png';
import shree_logo from '../assets/images/company/shree-logo.png';
import whatsapp from '../assets/images/company/whatsapp.png';
import React from 'react'
import { Link } from "react-router-dom";



export default function JobJob() {
    const jobGrids = [
        {
            image: facebook_logo,
            title: 'Software Engineering',
            day: ' Posted 3 Days ago',
            jobtype: 'Full Time',
            price: '$950 - $1100/mo',
            headline: 'Facebook Ltd.',
            country: 'Australia'
        },
        {
            image: google_logo,
            title: 'Web Developer',
            day: ' Posted 3 Days ago',
            jobtype: 'Remote',
            price: '$2500 - $2600/mo',
            headline: 'Google Ltd.',
            country: 'America'
        },
        {
            image: linkedin,
            title: 'UX/UI Designer',
            day: ' Posted 3 Days ago',
            jobtype: 'Freelance',
            price: ' $3500 - $3600/mo',
            headline: 'Linkedin Ltd.',
            country: 'Canada'
        },
        {
            image: skype,
            title: 'Human Resource(HR)',
            day: ' Posted 3 Days ago',
            jobtype: 'Part Time',
            price: ' $2000 - $2500/mo',
            headline: 'Skype Ltd.',
            country: 'UK'
        },
        {
            image: spotify,
            title: 'Web Designer',
            day: ' Posted 3 Days ago',
            jobtype: 'Full Time',
            price: '$1500 - $1600/mo',
            headline: 'Spotify Ltd.',
            country: 'China'
        },
        {
            image: telegram,
            title: 'Graphic Designer',
            day: ' Posted 3 Days ago',
            jobtype: 'Part  Time',
            price: ' $500 - $600/mo',
            headline: 'Telegram Ltd.',
            country: 'India'
        },
        {
            image: circle_logo,
            title: 'Software Engineering',
            day: ' Posted 3 Days ago',
            jobtype: 'Full Time',
            price: '$950 - $1100/mo',
            headline: 'Circle CI Ltd.',
            country: 'Australia'
        },
        {
            image: skype,
            title: 'Web Developer',
            day: ' Posted 3 Days ago',
            jobtype: 'Remote',
            price: '$2500 - $2600/mo',
            headline: 'Skype Ltd.',
            country: 'America'
        },
        {
            image: snapchat,
            title: 'UX/UI Designer',
            day: ' Posted 3 Days ago',
            jobtype: 'Freelance',
            price: ' $3500 - $3600/mo',
            headline: 'Snapchat Ltd.',
            country: 'Canada'
        },
        {
            image: shree_logo,
            title: 'Human Resource(HR)',
            day: ' Posted 3 Days ago',
            jobtype: 'Full Time',
            price: '$2000 - $2500/mo',
            headline: 'Shreethemes Ltd.',
            country: 'UK'
        },
        {
            image: telegram,
            title: 'Web Designer',
            day: ' Posted 3 Days ago',
            jobtype: 'Full Time',
            price: ' $1500 - $1600/mo',
            headline: 'Telegram Ltd.',
            country: 'China'
        },
        {
            image: whatsapp,
            title: 'Graphic Designer',
            day: ' Posted 3 Days ago',
            jobtype: 'Part Time',
            price: ' $500 - $600/mo',
            headline: 'Whatsapp Ltd.',
            country: 'India'
        }
    ];


    return (
        <>
            <div className="container mt-10">

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">

                    {jobGrids.map((item, index) => (
                        <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-800" key={index}>
                            <div className="p-6">
                                <Link to="#" className="title h5 text-lg font-semibold hover:text-emerald-600">{item.title}</Link>
                                <p className="text-slate-400 mt-2"><i className="uil uil-clock text-emerald-600"></i>{item.day}</p>

                                <div className="flex justify-between items-center mt-4">
                                    <span className="bg-emerald-600/5 text-emerald-600 text-xs font-bold px-2.5 py-0.5 rounded h-5">{item.jobtype}</span>

                                    <p className="text-slate-400"><i className="uil uil-usd-circle text-emerald-600"></i>{item.price}</p>
                                </div>
                            </div>

                            <div className="flex items-center p-6 border-t border-gray-100 dark:border-gray-700">
                                <img src={item.image} className="h-12 w-12 shadow-md dark:shadow-gray-800 rounded-md p-2 bg-white dark:bg-slate-900" alt="" />

                                <div className="ms-3">
                                    <h6 className="mb-0 font-semibold text-base">{item.headline}</h6>
                                    <span className="text-slate-400 text-sm">{item.country}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                    <div className="md:col-span-12 text-center">
                        <nav aria-label="Page navigation example">
                            <ul className="inline-flex items-center -space-x-px">
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                        <i className="uil uil-angle-left text-[20px] rtl:rotate-180 rtl:-mt-1"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">1</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">2</Link>
                                </li>
                                <li>
                                    <Link to="#" aria-current="page" className="z-10 w-[40px] h-[40px] inline-flex justify-center items-center text-white bg-emerald-600 border border-emerald-600">3</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">4</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">5</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                        <i className="uil uil-angle-right text-[20px] rtl:rotate-180 rtl:-mt-1"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

        </>
    )
}
