import React from 'react'
import facebook_logo from '../assets/images/company/facebook-logo.png';
import google_logo from '../assets/images/company/google-logo.png';
import android from '../assets/images/company/android.png';
import lenovo_logo from '../assets/images/company/lenovo-logo.png';
import spotify from '../assets/images/company/spotify.png';
import linkedin from '../assets/images/company/linkedin.png';
import circle_logo from '../assets/images/company/circle-logo.png';
import skype from '../assets/images/company/skype.png';
import snapchat from '../assets/images/company/snapchat.png';
import shree_logo from '../assets/images/company/shree-logo.png';
import telegram from '../assets/images/company/telegram.png';
import whatsapp from '../assets/images/company/whatsapp.png';
import { Link } from "react-router-dom";


export default function JobListCompFive() {
    const JobListCompFive = [
        {
            image: facebook_logo,
            title: 'Facebook',
            day: '2 days ago',
            headline: 'Web Designer / Developer',
            detail: 'Total Openings: 1',
            amount: '$950 - $1100/mo',
            verify: 'Verified',
            rating: ' 4.8',
            country: 'Australia',
        },
        {
            image: google_logo,
            title: 'Google',
            day: '2 days ago',
            headline: 'Marketing Director',
            detail: 'Total Openings: 1',
            amount: '$950 - $1100/mo',
            verify: 'Verified',
            rating: ' 4.8',
            country: 'USA',
        },
        {
            image: android,
            title: 'Android',
            day: '2 days ago',
            headline: 'Application Developer',
            detail: 'Total Openings: 1',
            amount: '$950 - $1100/mo',
            verify: 'Verified',
            rating: ' 4.8',
            country: 'China',
        },
        {
            image: lenovo_logo,
            title: 'Lenovo',
            day: '2 days ago',
            headline: 'Senior Product Designer',
            detail: 'Total Openings: 1',
            amount: '$950 - $1100/mo',
            verify: 'Verified',
            rating: ' 4.8',
            country: 'Dubai',
        },
        {
            image: spotify,
            title: 'Spotify',
            day: '2 days ago',
            headline: 'C++ Developer',
            detail: 'Total Openings: 1',
            amount: '$950 - $1100/mo',
            verify: 'Verified',
            rating: ' 4.8',
            country: 'India',
        },
        {
            image: linkedin,
            title: 'Linkedin',
            day: '2 days ago',
            headline: 'Php Developer',
            detail: 'Total Openings: 1',
            amount: '$950 - $1100/mo',
            verify: 'Verified',
            rating: ' 4.8',
            country: 'Pakistan',
        },
        {
            image: circle_logo,
            title: 'Facebook',
            day: '2 days ago',
            headline: 'Web Designer / Developer',
            detail: 'Total Openings: 1',
            amount: '$950 - $1100/mo',
            verify: 'Verified',
            rating: ' 4.8',
            country: 'Australia',
        },
        {
            image: skype,
            title: 'Google',
            day: '2 days ago',
            headline: 'Marketing Director',
            detail: 'Total Openings: 1',
            amount: '$950 - $1100/mo',
            verify: 'Verified',
            rating: ' 4.8',
            country: 'USA',
        },
        {
            image: snapchat,
            title: 'Android',
            day: '2 days ago',
            headline: 'Application Developer',
            detail: 'Total Openings: 1',
            amount: '$950 - $1100/mo',
            verify: 'Verified',
            rating: ' 4.8',
            country: 'China',
        },
        {
            image: shree_logo,
            title: 'Lenovo',
            day: '2 days ago',
            headline: 'Senior Product Designer',
            detail: 'Total Openings: 1',
            amount: '$950 - $1100/mo',
            verify: 'Verified',
            rating: ' 4.8',
            country: 'Dubai',
        },
        {
            image: telegram,
            title: 'Spotify',
            day: '2 days ago',
            headline: 'C++ Developer',
            detail: 'Total Openings: 1',
            amount: '$950 - $1100/mo',
            verify: 'Verified',
            rating: ' 4.8',
            country: 'India',
        },
        {
            image: whatsapp,
            title: 'Linkedin',
            day: '2 days ago',
            headline: 'Php Developer',
            detail: 'Total Openings: 1',
            amount: '$950 - $1100/mo',
            verify: 'Verified',
            rating: ' 4.8',
            country: 'Pakistan',
        }
    ];

    return (
        <>
            <div className="container md:mt-24 mt-16">
                <div className="grid md:grid-cols-2 mt-8 gap-[30px]" >
                    {JobListCompFive.map((item, index) => (
                        <div className="group rounded-lg shadow hover:shadow-lg dark:shadow-gray-700 transition-all duration-500" key={index}>
                            <div className="flex items-center justify-between p-6">
                                <div className="flex items-center">
                                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                                        <img src={item.image} className="h-8 w-8" alt="" />
                                    </div>

                                    <div className="ms-3">
                                        <Link to="/employer-detail" className="block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500">{item.title}</Link>
                                        <span className="block text-sm text-slate-400">{item.day}</span>
                                    </div>
                                </div>

                                <Link to="/job-apply" className="btn btn-icon btn-lg rounded-full bg-emerald-600/5 group-hover:bg-emerald-600 border border-slate-100 dark:border-slate-800 text-emerald-600 group-hover:text-white"><i className="uil uil-arrow-up-right"></i></Link>
                            </div>

                            <div className="lg:flex items-center justify-between border-t border-gray-100 dark:border-gray-800 p-6">
                                <div>
                                    <Link to="/job-detail-one" className="text-lg font-semibold hover:text-emerald-600">{item.headline}</Link>
                                    <p className="text-slate-400 mt-1">{item.detail}</p>
                                </div>

                                <p className="text-slate-400 lg:mt-0 mt-4"><i className="uil uil-usd-circle text-[20px] text-emerald-600"></i>{item.amount}</p>
                            </div>

                            <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 flex justify-between items-center">
                                <div>
                                    <span className="inline-block font-semibold"><i className="mdi mdi-check-decagram mdi-18px text-blue-500 me-1"></i>{item.verify}</span>
                                    <ul className="list-none inline-block ms-1 text-yellow-400">
                                        <li className="inline"><i className="mdi mdi-star text-lg"></i></li>
                                        <li className="inline"><i className="mdi mdi-star text-lg"></i></li>
                                        <li className="inline"><i className="mdi mdi-star text-lg"></i></li>
                                        <li className="inline"><i className="mdi mdi-star text-lg"></i></li>
                                        <li className="inline"><i className="mdi mdi-star text-lg"></i></li>
                                        <li className="inline text-slate-400 font-semibold">4.8</li>
                                    </ul>
                                </div>
                                <span className="inline-block me-1 text-slate-400"><i className="uil uil-map-marker text-[18px] text-slate-900 dark:text-white me-1"></i>{item.country}</span>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                    <div className="md:col-span-12 text-center">
                        <Link to="/job-list-five" className="btn btn-link text-slate-400 hover:text-emerald-600 after:bg-emerald-600 duration-500 ease-in-out">See More Jobs <i className="uil uil-arrow-right align-middle"></i></Link>
                    </div>
                </div>
            </div>
        </>
    )
}