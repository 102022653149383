import React from 'react'
import Image1 from '../assets/images/events/event1.jpg'
import Image2 from '../assets/images/events/event2.jpg'
import Image3 from '../assets/images/events/event3.png'


export default function PopularJobsSeven() {

    return (
        <div>
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Social Events & Program Participations</h3>

                    <p className="text-slate-300" style={{width: '70%', margin: '0 auto', textAlign: 'justify', textJustify: 'inter-word'}}>
                    Dedune Tropical is a proud partners to the Dumba festival planning committee. A festival celebrated by the Chiefs and people of the Wala Heritage in Wa, in the upper west region. We also partake in other festivals like Kundum, Yam and many other festivals. We also exhibit at various events like the Accra Art Fair, Adinkra International Arts and Craft Show, and many other events in the country and beyond. the company also participates in several social events and plays a key role in the agricultural sector by educating and helping multiple local farmers on modern farming trends.
                    </p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px] justify-center items-center">
                    <img src={Image1} alt="event1" className="w-full h-96 object-cover" />
                    <img src={Image2} alt="event2" className="w-full h-96 object-cover " />
                    <img src={Image3} alt="event3" className="w-full h-96 object-cover" style={{ paddingTop: '2rem' }} />
                </div>

            </div>

            {/* <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                    <div className="md:col-span-12 text-center">
                        <Link to="job-grid-four" className="btn btn-link text-slate-400 hover:text-emerald-600 after:bg-emerald-600 duration-500 ease-in-out">See More Jobs <i className="uil uil-arrow-right align-middle"></i></Link>
                    </div>
                </div> */}
        </div>
    )
}