// home three //

import React from 'react'
import { AlignCenter } from 'react-feather';
import { Link } from 'react-router-dom';

export default function Works() {
    const home = [
        {
            title: 'Target',
            deta: 'Our main targets are; Art collectors and enthusiasts, Museums and cultural institutions, Interior designers and decorators, Tourist and souvenir shops, Galleries and art dealers, African diaspora communities, Educational institutions, Home décor retail stores, Event planners and cultural event organizers and Gift shops and online retailers. The company is also focused on the development of the agricultural sector, with particular focus on the rice, beans, maize, and poultry value chains. The company is focused on production expansion and productivity enhancement.',
        },
        {
            title: 'Program Interventions',
            deta: 'We prioritize the preservation, promotion, and appreciation of African artifacts and sculptures by working closely with skilled artisans and craftsmen to bring you high-quality pieces that are sure to add character and culture to your space to create awareness about the significance of history. Our collection celebrates the diversity and creativity of African art. We are determined and dedicated to preserving and promoting the artistic heritage of Africa. The company also aims to expand production and enhance productivity, add value, and advocate for market and policy changes. It uses an enclave approach, combining market access and partnership leverage for agricultural investment to promote sustainability.'
        },
        {
            title: 'Development Model',
            deta: 'The company has a unique approach to its operations, focusing on the Dedune model. This model is designed to ensure that the company is Holistic, Business-driven, Private sector-led, and People-centered, with Youth engagement at the core of its operations. This approach is designed to ensure that the company is sustainable and has a positive impact on the community.',
        }
    ];

    return (

        <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center" style={{width: '80%', alignItems: 'center', margin: '0 auto', textAlign: 'justify', textJustify: 'inter-word'}}>
                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">
                    Overview of Dedune Tropical Company Limited
                </h3>
                <p className="text-left text-slate-300 max-w-sm mx-auto">
                    Dedune Tropical is a private sector company dedicated to African Artifacts and sculptures. Here, you will find a curated collection of unique and stunning pieces that showcase the rich cultural heritage and artistic traditions of Africa.
                </p>
                <br />
                <p className="text-left text-slate-300 max-w-sm mx-auto">
                    From intricately carved masks and statues to beautifully crafted beadwork and pottery, each piece tells a story and offers a glimpse into the diverse and vibrant cultures of the African continent. Whether you are a seasoned collector or simply appreciate the beauty and craftsmanship of African art, we invite you to explore our collection and discover the beauty and history of these incredible artifacts.
                </p>
                <br />
                <p className="text-left text-slate-300 max-w-sm mx-auto">
                    Dedune Tropical, a leading Agribusiness company is also dedicated to providing innovative solutions for agricultural production and sustainability. With years of experience in the industry, we specialize in offering a wide range of services and products to help farmers achieve their goals and maximize their yields.
                </p>
                <br />
                <p className="text-left text-slate-300 max-w-sm mx-auto">
                    At Dedune Tropical, we understand the challenges faced by farmers and the ever-evolving landscape of modern agriculture. That's why we are committed to staying ahead of the curve and providing our customers with the latest technologies, best practices, and expert advice to help them succeed.
                </p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-6 gap-[30px]">
                {home.map((item, index) => (
                    <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 transition duration-500 rounded-2xl mt-6 text-center" key={index}>
                        <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                            {/* <i className="uil uil-airplay"></i> */}
                            <i className="uil uil-sun"></i>
                        </div>

                        <div className="content mt-7" >
                            <Link to="#" className="title h5 text-lg font-semibold hover:text-emerald-600">{item.title}</Link>
                            <p className="text-slate-400 mt-3">{item.deta}</p>

                            <div className="mt-5">
                                <Link to="#" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></Link>
                            </div>
                        </div>

                    </div>

                ))}
            </div>
        </div>

    )
}