import React from 'react'
import { HelpCircle } from 'react-feather'


export default function QuesAnswer() {
    const QuesAnswerData = [
        {
            question: 'What are some key factors to consider when starting an agricultural business?',
            answer: 'When starting an agricultural business, it is essential to consider factors such as market demand for your products, available land and resources, access to water and irrigation, knowledge of local growing conditions, potential regulatory requirements, and financial feasibility including startup costs and potential revenue streams.',
        },
        {
            question: 'How can farmers mitigate risks associated with weather and climate change?',
            answer: 'Diversify crops and livestock: Farmers can minimize the impact of weather and climate change by growing a variety of crops and raising diverse livestock. This way, if one crop or livestock is affected by adverse weather, the farmer still has other sources of income. ',
        },
        {
            question: 'What are some common challenges faced by small-scale farmers, and how can they overcome them?',
            answer: 'Some common challenges faced by small-scale farmers include limited access to resources, lack of access to markets, and limited access to financial services. To overcome these challenges, small-scale farmers can form cooperatives to pool resources, access markets, and access financial services. They can also seek out grants and loans specifically designed for small-scale agriculture, diversifying their income streams, participating in local food networks, and advocating for policies that support small-scale agriculture.,'
        },
        {
            question: 'How important is technology in modern agriculture?',
            answer: ' Technology plays a crucial role in modern agriculture, helping farmers improve efficiency, productivity, and sustainability. It helps to reduce post harvest losses, improve crop yields, and conserve natural resources.'
        }
    ]
    return (
        <div className="container md:mt-24 mt-16">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Questions & Answers</h3>

                <p className="text-slate-300 max-w-xl mx-auto">
                    Have a question? We have answers. If you can't find what you're looking for, feel free to contact us. 
                </p>
            </div>


            <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                {QuesAnswerData.map((item, index) => (
                    <div className="flex" key={index}>
                        <HelpCircle className="h-8 text-emerald-600 me-3" ></HelpCircle>
                        <div className="flex-1">
                            <h5 className="mb-2 text-lg font-semibold">{item.question}<span className="text-emerald-600"></span> </h5>
                            <p className="text-slate-400">{item.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

