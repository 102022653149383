import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";

import logo_icon_40 from '../assets/images/logo-icon-40.png';
import logo_icon_40_white from '../assets/images/logo-icon-40-white.png';
import dedune_logo from '../assets/images/dedune_logo.png';
import dedune_logo_dark from '../assets/images/dedune_logo_dark.png';


export default function Navbar(props) {
    const { navClass, topnavClass, isContainerFluid } = props;
    const [isOpen, setMenu] = useState(true);

    window.addEventListener("scroll", windowScroll);
    useEffect(() => {
        activateMenu();
    }, []);

    function windowScroll() {
        const navbar = document.getElementById("topnav");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            if (navbar !== null) {
                navbar?.classList.add("nav-sticky");
            }
        } else {
            if (navbar !== null) {
                navbar?.classList.remove("nav-sticky");
            }
        }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }

    const toggleMenu = () => {
        setMenu(!isOpen)
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    }


    const getClosest = (elem, selector) => {

        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;

    };

    const activateMenu = () => {
        var menuItems = document.getElementsByClassName("sub-menu-item");
        if (menuItems) {

            var matchingMenuItem = null;
            for (var idx = 0; idx < menuItems.length; idx++) {
                if (menuItems[idx].href === window.location.href) {
                    matchingMenuItem = menuItems[idx];
                }
            }

            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');


                var immediateParent = getClosest(matchingMenuItem, 'li');

                if (immediateParent) {
                    immediateParent.classList.add('active');
                }

                var parent = getClosest(immediateParent, '.child-menu-item');
                if (parent) {
                    parent.classList.add('active');
                }

                var parent = getClosest(parent || immediateParent, '.parent-menu-item');

                if (parent) {
                    parent.classList.add('active');

                    var parentMenuitem = parent.querySelector('.menu-item');
                    if (parentMenuitem) {
                        parentMenuitem.classList.add('active');
                    }

                    var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                } else {
                    var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                }
            }
        }
    }
    return (
        <nav id="topnav" className={`defaultscroll is-sticky ${topnavClass}`}>
            <div className={`${isContainerFluid === true ? 'container-fluid md:px-8 px-3' : 'container-md px-4'}`}>
                <Link className="logo" to="/">

                    <div className="block sm:hidden">
                        <img src={logo_icon_40} className="h-10 inline-block dark:hidden" alt="" />
                        <img src={logo_icon_40_white} className="h-10 hidden dark:inline-block" alt="" />
                    </div>

                    {navClass && navClass.includes('nav-light') ?

                        <div className="sm:block hidden">
                            <span className="inline-block dark:hidden">
                                <img src={dedune_logo_dark} className="h-[50px] l-dark" alt="" />
                                <img src={dedune_logo} className="h-[50px] l-light" alt="" />
                            </span>
                            <img src={dedune_logo} className="h-[50px] hidden dark:inline-block" alt="" />
                        </div> :
                        <div className="sm:block hidden">
                            <img src={dedune_logo_dark} className="h-[50px] inline-block dark:hidden" alt="" />
                            <img src={dedune_logo} className="h-[50px] hidden dark:inline-block" alt="" />
                        </div>
                    }
                </Link>

                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to="#" className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>
                {/* <!-- End Mobile Toggle --> */}

                {/* <!--Login button Start--> */}
                <ul className="buy-button list-none mb-0">
                    <li className="inline-block mb-0">
                        <div className="relative top-[3px]">
                            <i className="uil uil-search text-lg absolute top-[3px] end-3"></i>
                            <input type="text" className="form-input h-9 pe-10 rounded-3xl sm:w-44 w-36 border-gray-100 dark:border-slate-800 bg-white dark:bg-slate-900" name="s" id="searchItem" placeholder="Search..." />
                        </div>
                    </li>
                    {/* <li className="inline-block ps-1 mb-0">
                        <Link to="/" className="btn btn-icon rounded-full bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white"><img src={image} className="rounded-full" alt="" /></Link>
                    </li> */}
                </ul>
                <div id="navigation" className={`${isOpen === true ? 'hidden' : 'block'}`}>
                    <ul className={`navigation-menu ${navClass}`}>
                        <li><Link to="/" className="sub-menu-item">Home</Link></li>
                        <li><Link to="/aboutus" className="sub-menu-item">About Us</Link></li>
                        {/* <li><Link to="/job-categories" className="sub-menu-item">Value Chain</Link></li> */}
                        <li><Link to="/contact" className="sub-menu-item">Contact</Link></li>
                    </ul>
                </div>
            </div>
        </nav>

    )
}
