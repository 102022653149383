import React, { useRef, useState } from 'react'
import contact from '../assets/images/svg/contact.svg';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import emailjs from '@emailjs/browser';
import { notification } from 'antd'

export default function Contact() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const form = useRef();

    const contactForm = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_b92xb0t', 'template_wxpj34l', form.current, 'TEXNuPkzwgKl_tX_T')
            .then((result) => {
                notification.success({
                    message: 'Success',
                    description:
                        'Email sent successfully',
                });
            }, (error) => {
                notification.error({
                    message: 'Error',
                    description:
                        'Email not sent',
                });
            });
    }
    return (
        <>
            <Navbar navClass='justify-end' />
            <div className="container-fluid relative mt-20">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe title='farm-location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3971.4379677090787!2d-0.40223612472180365!3d5.501800734191713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdfbbe867bd225b%3A0x2babfba8f63bcb29!2sKasoa%20Tuba%20Irrigation%20Farm!5e0!3m2!1sen!2sgh!4v1707636798358!5m2!1sen!2sgh" width="600" height="450" style={{ border: 0 }} className="w-full h-[500px]" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <section className="relative lg:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={contact} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-semibold">Get in touch !</h3>

                                    <form ref={form} onSubmit={contactForm}>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="name" className="font-semibold">Your Name:</label>
                                                <input name="from_name" id="name" type="text"
                                                    className="form-input mt-2" placeholder="Name :"
                                                    onChange={(event) => setName(event.target.value)}
                                                />
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="email" className="font-semibold">Your Email:</label>
                                                <input name="from_email" id="email" type="email" className="form-input mt-2"
                                                    placeholder="Email :"
                                                    onChange={(event) => setEmail(event.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                                <input name="from_subject" id="subject" className="form-input mt-2"
                                                    placeholder="Subject :"
                                                    onChange={(event) => setSubject(event.target.value)}
                                                />
                                            </div>

                                            <div className="mb-5">
                                                <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                <textarea name="message" id="comments" className="form-input mt-2 textarea"
                                                    placeholder="Message :"
                                                    onChange={(event) => setMessage(event.target.value)}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <button type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md">Send Message</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container lg:mt-24 mt-16">
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                        <div className="text-center px-6">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <i className="uil uil-phone"></i>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Phone</h5>
                                <p className="text-slate-400 mt-3">
                                    (+233) 024 953 7336
                                </p>
                            </div>
                        </div>

                        <div className="text-center px-6">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <i className="uil uil-envelope"></i>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Email</h5>
                                <p className="text-slate-400 mt-3">
                                    dedunetropical@gmail.com <br />
                                </p>
                            </div>
                        </div>

                        <div className="text-center px-6">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <i className="uil uil-map-marker"></i>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Location</h5>
                                <p className="text-slate-400 mt-3">
                                    Kasoa tuba</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
