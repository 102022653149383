import React from 'react'
import spotify from '../assets/images/company/spotify.png';
import facebook_logo from '../assets/images/company/facebook-logo.png';
import google_logo from '../assets/images/company/google-logo.png';
import android from '../assets/images/company/android.png';
import lenovo_logo from '../assets/images/company/lenovo-logo.png';
import shree_logo from '../assets/images/company/shree-logo.png';
import skype from '../assets/images/company/skype.png';
import snapchat from '../assets/images/company/snapchat.png';
import circle_logo from '../assets/images/company/circle-logo.png';
import telegram from '../assets/images/company/telegram.png';
import whatsapp from '../assets/images/company/whatsapp.png';
import { Link } from 'react-router-dom';

export default function EmployerList() {
    const EmployerList = [
        {
            brandimage: spotify,
            title: 'Spotify',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'Australia',
            job: '6 Jobs'
        },
        {
            brandimage: facebook_logo,
            title: 'Facebook',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'USA',
            job: '6 Jobs'
        },
        {
            brandimage: google_logo,
            title: 'Google',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'China',
            job: '6 Jobs'
        },
        {
            brandimage: android,
            title: 'Android',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'Dubai',
            job: '6 Jobs'
        },
        {
            brandimage: lenovo_logo,
            title: 'Lenovo',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'Pakistan',
            job: '6 Jobs'
        },
        {
            brandimage: shree_logo,
            title: 'Shreethemes',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'India',
            job: '6 Jobs'
        },
        {
            brandimage: skype,
            title: 'Skype',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'Rush',
            job: '6 Jobs'
        },
        {
            brandimage: snapchat,
            title: 'Snapchat',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'Turkey',
            job: '6 Jobs'
        },
        {
            brandimage: circle_logo,
            title: 'Circle CI',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'Pakistan',
            job: '6 Jobs'
        },
        {
            brandimage: telegram,
            title: 'Telegram',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'India',
            job: '6 Jobs'
        },
        {
            brandimage: whatsapp,
            title: 'Whatsapp',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'Rush',
            job: '6 Jobs'
        },
        {
            brandimage: spotify,
            title: 'Spotify',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'Turkey',
            job: '6 Jobs'
        }
    ]
    return (
        <>

            <div className="container">
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px]">
                    {EmployerList.map((item, index) => (
                        <div className="group relative p-6 rounded-md shadow dark:shadow-gray-700 mt-6">
                            <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-700 rounded-md relative -mt-12" key={index}>
                                <img src={item.brandimage} className="h-8 w-8" alt="" />
                            </div>

                            <div className="mt-4">
                                <Link to="#" className="text-lg hover:text-emerald-600 font-semibold">{item.title}</Link>
                                <p className="text-slate-400 mt-2">{item.deta}</p>
                            </div>

                            <div className="mt-4 pt-4 border-t border-gray-100 dark:border-gray-700 flex justify-between">
                                <span className="text-slate-400"><i className="uil uil-map-marker"></i>{item.country}</span>
                                <span className="block font-semibold text-emerald-600">{item.job}</span>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                    <div className="md:col-span-12 text-center">
                        <nav aria-label="Page navigation example">
                            <ul className="inline-flex items-center -space-x-px">
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                        <i className="uil uil-angle-left text-[20px] rtl:rotate-180 rtl:-mt-1"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">1</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">2</Link>
                                </li>
                                <li>
                                    <Link to="#" aria-current="page" className="z-10 w-[40px] h-[40px] inline-flex justify-center items-center text-white bg-emerald-600 border border-emerald-600">3</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">4</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">5</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                        <i className="uil uil-angle-right text-[20px] rtl:rotate-180 rtl:-mt-1"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}
