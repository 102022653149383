import React, { useState } from 'react'
import ab01 from '../assets/images/about/ab01.jpg';
import chiken from '../assets/images/about/r.webp';
import egg from '../assets/images/about/egg-farm.webp'
import ModalVideo from 'react-modal-video'
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import { Link } from "react-router-dom";


export default function MillionsJob() {
    const [isOpen, setOpen] = useState(false)
    return (
        <>
            {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="yba7hPeTSjk" onClose={() => setOpen(false)} /> */}
            <div className="grid items-center justify-center mx-auto" style={{ paddingBottom: '5rem', width: '80%' }}>
                <h6 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium" style={{ margin: '0 auto', textAlign: 'justify', textJustify: 'inter-word' }}>
                    Dedune Tropical is a leading company in African Artefacts and sculptures, and very vibrant in Agribusiness.
                </h6>
                <p style={{marginTop: '2rem', paddingBottom: '1rem', textAlign: 'justify', textJustify: 'inter-word' }}>
                    Dedune Tropical Company Limited was established in 2019 and has been a trusted name in the African Artefacts and sculptures and Agribusiness Industry providing quality products and services to our clients. Our mission is to promote and preserve African history through arts and crafts and also be involved in the Agribusiness revolution.
                </p>
                <p style={{paddingBottom: '1rem', textAlign: 'justify', textJustify: 'inter-word' }}>
                    We foster strong partnerships by working closely with skilled artisans and craftsmen to bring you high-quality pieces that are sure to add character and culture to your space to create awareness about the significance of history. Our collection celebrates the diversity and creativity of African art. We are determined and dedicated to preserving and promoting the artistic heritage of Africa. The company also have a very key interest in revolutionizing the Agribusiness sector by employing cutting edge technologies and strategies to optimize crop yield, drive efficiency and preserve natural resources.
                </p>
                <p style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
                    Understanding that collaboration is key to our shared success, we collaborate with  our local farmers to make them understand the need modernize agriculture, and also promote awareness within our farming communities by organizing training programs and workshops to empower our local farmers and aspiring agricultural enthusiasts with knowledge needed to adapt to the changing trends and market dynamics.
                </p>
            </div>
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                <div className="lg:col-span-5 md:col-span-6">
                    <div className="relative">
                        <div className="relative">
                            <img src={chiken} className="lg:w-[400px] w-[280px] rounded-md shadow dark:shadow-gray-700" alt="" />
                            {/* <div className="absolute top-0 translate-y-2/4 end-0 text-center">
                                <Link to="#" onClick={() => setOpen(true)} data-type="youtube" data-id="yba7hPeTSjk" className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-700 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-emerald-600 dark:text-white">
                                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                </Link>
                            </div> */}
                        </div>
                        <div className="absolute md:-end-5 end-0 -bottom-16">
                            <img src={egg} className="lg:w-[200px] w-[200px] border-8 border-white dark:border-slate-900 rounded-md shadow dark:shadow-gray-700" alt="" />
                        </div>
                    </div>
                </div>

                <div className="lg:col-span-7 md:col-span-6 mt-14 md:mt-0">
                    <div className="lg:ms-5">
                        <h3 className="mb-6 md:text-[26px] text-2xl md:leading-normal leading-normal font-semibold">We're a trusted partner in farming.</h3>

                        <p className="text-slate-400 max-w-xl">We unlock value for customers, enable farming communities to prosper sustainably and strive for a food-secure future.</p>

                        <ul className="list-none text-slate-400 mt-4">
                            <li className="mb-1 flex"><i className="uil uil-check-circle text-emerald-600 text-xl me-2"></i>
                                Our mission is to help farmers and farming communities to prosper sustainably.
                            </li>
                            <li className="mb-1 flex"><i className="uil uil-check-circle text-emerald-600 text-xl me-2"></i>
                                We are committed to providing the best quality products and services to our customers.
                            </li>
                            <li className="mb-1 flex"><i className="uil uil-check-circle text-emerald-600 text-xl me-2"></i>
                                We are committed to helping farmers and farming communities in practicing mechanized and modern farming.
                            </li>
                        </ul>

                        <div className="mt-6">
                            <Link to="/contact" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white mt-2 rounded-md"><i className="uil uil-envelope"></i> Contact us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
